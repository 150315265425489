import setAuthorizationToken from '../utils/setAuthorizationToken';

let initialState = {};

if (localStorage[process.env.REACT_APP_LOCAL_STORAGE_KEY]) {
    initialState = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY));
    setAuthorizationToken(initialState.token);
}

const authentication = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            state = {
                isAuthenticated: true,
                token: action.token,
                user: action.payload
            };
            localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY, JSON.stringify(state));
            return state;

        case 'USER_DATA':
            state = {
                ...state,
                user: {
                    ...state.user,
                    ...action.user,
                },
            };
            localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY, JSON.stringify(state));
            return state;

        case 'USER_IMAGE':
            state = {
                ...state,
                user: {
                    ...state.user,
                    profile_pic: [action.image]
                },
            };
            localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY, JSON.stringify(state));
            return state;

        case 'USER_TFA_ENABLED':
            state = {
                ...state,
                user: {
                    ...state.user,
                    tfa_enabled: true,
                },
            };
            localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY, JSON.stringify(state));
            return state;

        case 'LOGOUT':
            state = {
                isAuthenticated: false,
                user: {},
                token: false,
            };
            localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY);
            return state;

        default:
            return state;
    };
};

export default authentication;
