import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import checkRole from '../utils/checkRole';

const Sidebar = (props) => {
    useEffect(() => {
        $('.js__accordion').each(function() {
            var selector = $(this);
            selector.find('.js__control').on('click', function(event) {
                event.preventDefault();
                if ($(this).parent().hasClass('active')) {
                    $(this).parent().removeClass('active');
                    $(this).next().stop().slideUp(400);
                } else {
                    selector.find('.active').children('.js__content').stop().slideUp(400);
                    selector.find('.active').removeClass('active');
                    $(this).parent().addClass('active');
                    $(this).next('.js__content').slideDown(400);
                }
            });
        });

        $('.js__menu_mobile').on('click', function() {
            $('html').toggleClass('menu-active');
            $(window).trigger('resize');
        });

        $('.js__menu_close').on('click', function() {
            $('html').removeClass('menu-active');
        });

        $('body').on('click', function(event) {
            if ($('html.menu-active').length && $(window).width() < 800) {
                var selector = $(event.target);
                if (!(selector.hasClass('main-menu') || selector.hasClass('js__menu_mobile') || selector.parents('.main-menu').length || selector.parents('.js__menu_mobile').length)) {
                    $('html').removeClass('menu-active');
                }
            }
        });
    }, []);

    return (
        <section>
            <div className="main-menu">
                <header className="header">
                    <NavLink to='/' className="logo">GSN AFP</NavLink>
                    <button type="button" className="button-close fa fa-times js__menu_close"></button>
                    <div className="user">
                        <NavLink to="/profile" className="avatar sidebar-profile bg-info d-flex">
                            <div className="admin-pic-placeholder">
                                <span className="initials justify-content-center align-self-center">{props?.user?.first_name.split('')[0]}{props?.user?.last_name.split('')[0]}</span>
                            </div>
                        </NavLink>
                        <h5 className="name"><NavLink to="/profile">{props.user.name}</NavLink></h5>
                        <h5 className="position">{props.user.brand}</h5>
                    </div>
                </header>
                <div className="content">
                    <div className="navigation">
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-chart-line"></i><span>Dashboard</span></NavLink>
                            </li>
                        </ul>
                        {
                            checkRole('barcode_scan') && <>
                                <h5 className="title">Acciones</h5>
                                <ul className="menu js__accordion">
                                    <li>
                                        <NavLink to="/scanner" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-barcode"></i><span>Escanear Articulos</span></NavLink>
                                    </li>
                                </ul>
                            </>
                        }
                        {
                            (checkRole('articles_read') || checkRole('images_read') || checkRole('uploads_read')) &&
                            <h5 className="title">Lists</h5>
                        }
                        <ul className="menu js__accordion">
                            {
                                checkRole('articles_read') &&
                                <li>
                                    <NavLink to="/articles" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-tshirt"></i><span>Artículos</span></NavLink>
                                </li>
                            }
                            {
                                checkRole('descriptions_read') &&
                                <li>
                                    <NavLink to="/descriptions" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-edit"></i><span>Descripciones</span></NavLink>
                                </li>
                            }
                            {
                                checkRole('images_read') &&
                                <li>
                                    <NavLink to="/images" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-image"></i><span>Imágenes</span></NavLink>
                                </li>
                            }
                            {
                                checkRole('uploads_read') &&
                                <li>
                                    <NavLink to="/uploads" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-upload"></i><span>Subidas</span></NavLink>
                                </li>
                            }
                            {
                                checkRole('promos_read') &&
                                <li>
                                    <NavLink to="/promos" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-percent"></i><span>Promociones</span></NavLink>
                                </li>
                            }
                        </ul>
                        {
                            (checkRole('shops_read'))
                            &&
                            <div>
                                <h5 className="title">Pickup Pedidos</h5>
                                <ul className="menu js__accordion">
                                    {
                                        checkRole('shops_read') &&
                                        <li>
                                            <NavLink to="/shops" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fa fa-store"></i><span>Locales</span></NavLink>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                        <h5 className="title">Analytics</h5>
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/analytics/scan-logs" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-list-ol"></i><span>Historial de Escaneos</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/analytics/scans-by-shop" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-chart-pie"></i><span>Escaneos por Local</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/analytics/scans-by-code" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-chart-line"></i><span>Escaneos por Código</span></NavLink>
                            </li>
                        </ul>
                        {
                            checkRole('uploads_export') && <>
                                <h5 className="title">Exportar Datos</h5>
                                <ul className="menu js__accordion">
                                    <li>
                                        <NavLink to="/export" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-upload"></i><span>Exportar Imagenes</span></NavLink>
                                    </li>
                                </ul>
                            </>
                        }
                        {
                            (checkRole('settings_read') || checkRole('admins_read'))
                            &&
                            <div>
                                <h5 className="title">Configuración</h5>
                                <ul className="menu js__accordion">
                                    {
                                        checkRole('settings_read') &&
                                        <li>
                                            <NavLink to="/settings" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-sliders-h"></i><span>Opciones</span></NavLink>
                                        </li>
                                    }
                                    {
                                        checkRole('settings_read') &&
                                        <li>
                                            <NavLink to="/destinations" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-server"></i><span>Destinos</span></NavLink>
                                        </li>
                                    }
                                    {
                                        checkRole('admins_read') &&
                                        <li>
                                            <NavLink to="/admin-users" className={({ isActive }) => isActive ? 'current' : ''}><i className="menu-icon fas fa-users-cog"></i><span>Administradores</span></NavLink>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => ({ user: state.auth.user, brand: state.brand });

export default connect(mapStateToProps)(Sidebar);
