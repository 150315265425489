import React from 'react';
import { connect } from 'react-redux';

const year = new Date().getFullYear();

const Footer = (props) => {
    return (
        <footer className="footer">
            <ul className="list-inline">
                <li className="list-inline-item">{year} © Carbonbase</li>
            </ul>
            <small>Time zone for all dates and times: {props.timeZone}. {process.env.REACT_APP_VERSION && <span>&mdash; Version {process.env.REACT_APP_VERSION}</span>}</small>
        </footer>
    );
};

const mapStateToProps = (state) => ({ timeZone: state.auth.user.timezone });

export default connect(mapStateToProps)(Footer);
