import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import withRouter from './WithRouter';
import $ from 'jquery';
import swal from 'sweetalert2';
import io from 'socket.io-client';
import checkRole from '../utils/checkRole';
import { triggerProcess } from '../services/articles';

const socket = io(process.env.REACT_APP_SOCKET_URL);

const NavBar = (props) => {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [upload, setUpload] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        socket.on('connect', () => {
            setIsConnected(true);

            const engine = socket.io.engine;

            engine.on("packet", ({ type, data }) => {
                // called for each packet received
                if (type === 'message') {
                    const json = JSON.parse(data.slice(1, data.length));
                    const event = json[0];
                    const args = json[1];
                    if (event === 'subida') {
                        setUpload(args);
                    }

                    if (event === 'imagen') {
                        setImage(args);
                    }
                }
            });
        });

        socket.on('disconnect', () => {
            setIsConnected(false);
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('pong');
        };
    }, []);

    useEffect(() => {
        // console.log('connected:', isConnected);
    }, [isConnected]);

    const toggleDropdown = (e) => {
        e.preventDefault();
        if ($(".js__toggle").hasClass("active")) {
            $(".js__toggle").removeClass("active");
        } else {
            $(".js__toggle").addClass("active");
        }
    };

    const signout = () => {
        swal.fire({
            title: "Logout?",
            text: "Are you sure you want to logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Sign Out',
            cancelButtonText: 'Stay',
            reverseButtons: true,
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            (value) => {
                if (value.isConfirmed) {
                    props.logout();
                }
            }
        );
    };

    useEffect(() => {
        $(".js__drop_down_button").on("click", function() {
            if ($(window).width() > 1025) {
                $(".js__drop_down").toggleClass("active");
            }
        });
    });

    const process = async () => {
        await triggerProcess();
        swal.fire({
            title: "Articles Process",
            text: "The articles file is being processed",
            icon: "success",
        });
    };

    return (
        <div className="fixed-navbar">
            <div className="float-left">
                <button type="button" className="menu-mobile-button fas fa-bars js__menu_mobile"></button>
                <h1 className="page-title">{props.brand.name}</h1>
            </div>
            <div className="float-right">
                {
                    checkRole('articles_process') &&
                    <span className="ico-item" onClick={process}>
                        <i className="fas fa-retweet" />
                    </span>
                }
                <NavLink to="/profile" className="ico-item notice-alarm">
                    <i className="fas fa-cog" />
                </NavLink>
                <span className={`ico-item ${upload ? 'pulse' : ''}`} onClick={toggleDropdown}><span className="ico-item fa fa-bolt notice-alarm js__toggle_open noticon" data-target="#notification-popup"></span></span>
                <span className="ico-item fas fa-power-off" onClick={signout}></span>
            </div>
            <div id="notification-popup" className="notice-popup js__toggle" data-space="75" style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
                <h2 className="popup-title">Procesos en curso</h2>
                <div className="content">
                    <ul className="notice-list">
                        {
                            upload && (
                                <li>
                                    <span className="dropdown-item">
                                        <span className="d-flex justify-content-between">
                                            <span>
                                                <span className="name">Subiendo Imagen</span>
                                                <span className="desc">{upload.uri}</span>
                                            </span>
                                            <span className="badge badge-pill badge-primary align-self-center">1</span>
                                        </span>
                                    </span>
                                </li>
                            )
                        }
                        {
                            image && (
                                <li>
                                    <span className="dropdown-item">
                                        <span className="d-flex justify-content-between">
                                            <span>
                                                <span className="name">Procesando Imagen Local</span>
                                                <span className="desc">{image}</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    token: state.auth.token,
    brand: state.brand,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({ type: 'LOGOUT' })
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));
