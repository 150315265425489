import $ from 'jquery';

let initialState = { loading: false, loader: 'FULLSCREEN' };

const loading = (state = initialState, action) => {
    switch (action.type) {
        case 'LOADING':
            state = {
                loading: true
            };
            $(document.body).addClass('body-loading');
            return state;
        case 'LOADED':
            state = {
                loading: false
            };
            $(document.body).removeClass('body-loading');
            return state;
        default:
            return state;
    };
};

export default loading;
